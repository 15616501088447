import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFares from "../components/metro-fares"

class ServiceBR13Page extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="BR13"
          description="View route information and buy your ticket for the BR13 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">BR13 service</h1>
              <p>Oulton - Brigshaw High School (Via Lower Mickletown)</p>
              <p>Brigshaw High School – Oulton (Via Lower Mickletown)</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
              <h2>Route</h2>
                <p style={{ margin: '15px 0'}}>This service will pick up/set down at all regular bus stops along the following route:</p>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="BR13 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=19H4TV8Ww8D0n508a02gwhuTGk3KneTo&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Brigshaw High School
                </p>
                <p>
                  From Wakefield Road/Oulton Drive via Wakefield Road, Newmarket Lane, Watergate, Barnsdale Road, Pinfold Lane, Main Street, Mickletown Road, Savile Road, Pinfold Lane, Lower Mickletown, Boat Lane, Green Lane, Barnsdale Road, Methley Road to Methley Road/School Street then non-stop to School.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="BR13 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=18meCl6wrKZF9N7Us0AVcQaJK06SJCPs&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Brigshaw High School
                </p>
                <p>
                  From school non-stop to Methley Road/Wellington Street then via Methley Road, Barnsdale Road, Green Lane, Boat Lane, Lower Mickletown, Pinfold Lane, Main Street, Mickletown Road, Savile Road, Pinfold Lane, Barnsdale Road, Watergate, Newmarket Lane and Wakefield Road.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Wakefield Road/Oulton Drive</td>
                      <td>DEP</td>
                      <td>0725</td>
                    </tr>
                    <tr>
                      <td>Watergate/Pinders Green Drive</td>
                      <td>DEP</td>
                      <td>0740</td>
                    </tr>
                    <tr>
                      <td>Mickletown Road/Savile Road</td>
                      <td>DEP</td>
                      <td>0750</td>
                    </tr>
                    <tr>
                      <td>Barnsdale Road/Green Lane</td>
                      <td>DEP</td>
                      <td>0800</td>
                    </tr>
                    <tr>
                      <td>Brigshaw High School</td>
                      <td>ARR</td>
                      <td>0820</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Brigshaw High School</td>
                      <td>DEP</td>
                      <td>1510</td>
                    </tr>
                    <tr>
                      <td>Barnsdale Road/Green Lane</td>
                      <td>DEP</td>
                      <td>1530</td>
                    </tr>
                    <tr>
                      <td>Mickletown Road/Savile Road</td>
                      <td>DEP</td>
                      <td>1540</td>
                    </tr>
                    <tr>
                      <td>Barnsdale Road/Green Row</td>
                      <td>DEP</td>
                      <td>1550</td>
                    </tr>
                    <tr>
                      <td>Wakefield Road/Oulton Drive</td>
                      <td>ARR</td>
                      <td>1605</td>
                    </tr>
                  </tbody>
                </table>

                <MetroFares />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default ServiceBR13Page
